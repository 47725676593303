<template>
    <div>
        <navBar nav="地区选择" />
        <div class="content">
            <div v-for="item in country" :key="item" class="main" @click="back(item.nameChinese)">
                <div>{{ item.nameEn }}</div>
                <div>{{ item.nameChinese }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import navBar from "../../components/navBar/index.vue"
import store from "../../store/otcStore"
export default {
    components: {
        navBar
    },
    data() {
        return {
            country: []
        }
    },
    mounted() {
        this.getCountry()
    },
    methods: {
        //获取国家
        getCountry() {
            this.$nextTick(() => {
                this.$mts.post({
                    url: '/uc/support/country',
                    data: {
                    }, success: (response) => {
                        this.country = response.data.data.map(item => {
                            return {
                                nameChinese: item.zhName,
                                nameEn: item.enName
                            }
                        })
                        
                    }
                });
            })
        },
        back(country) {
            store.commit('setCard', country)
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
.content {
    width: 100%;
    background-color: #fff;
    height: 30.5344vw;
}

.main {
    height: 13.7405vw;
    padding-left: 3.8168vw;
    padding-top: 1.2723vw;

}
</style>